<script>
export default {
  render(createElement) {
    // The content won't be displayed to the, by just in case.
    return createElement('div', 'Logging out...');
  },
  beforeMount() {
    this.$store.dispatch('session/logout');
    this.$router.push({ name: 'login', query: this.$route.query });
  }
}
</script>